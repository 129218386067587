import { makeRequest } from '../'
import { USER_PATH } from './'

const NOTIFICATIONS_PATH = USER_PATH + '/notifications'

export interface Notification {
  notification_id: string
  user: string
  date: Date
  subject: string
  message: string
  read: boolean
}

type APINotification = Omit<Notification, 'date'> & { date: string }

export const getNotifications = async (options?: {
  unreadOnly?: boolean
  limit?: number
}): Promise<Notification[]> => {
  const response = await makeRequest({
    url: NOTIFICATIONS_PATH,
    method: 'GET',
    params: {
      ...(options?.unreadOnly ? { unread_only: true } : {}),
      ...(options?.limit ? { limit: options.limit } : {}),
    },
  })

  const notifications = response.data.data.map(
    (notification: APINotification) => ({
      ...notification,
      date: new Date(notification.date),
    })
  )

  return notifications
}

export const markNotificationsAsRead = async (ids: string[]) => {
  const response = await makeRequest({
    url: NOTIFICATIONS_PATH + '/read',
    method: 'POST',
    data: { ids },
  })

  return response.data
}
