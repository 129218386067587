import classNames from 'classnames'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import type { Notification } from '../../clients/compass/notifications'
import { XIcon } from '../../components/icons'
import { Path } from '../../utils/constants'
import styles from './Notifications.module.css'

interface Props {
  className?: string
  notifications: Notification[]
  closedIds: string[]
  readIds: string[]
  onClose: (id: string) => void
  onRead: (id: string) => void
}

const formatDate = (date: Date) => moment(date).format('DD/MM/YYYY HH:mm')

const Notifications: React.FC<Props> = (props) => {
  return (
    <div
      className={classNames(
        'absolute top-12 right-0 z-10 w-64',
        props.className
      )}
    >
      {props.notifications
        .filter(
          (notification) =>
            !props.closedIds.includes(notification.notification_id)
        )
        .map((notification) => (
          <div
            key={notification.notification_id}
            className={classNames(
              'mx-4 my-2 flex items-start justify-between rounded-md bg-white p-2 shadow-md',
              styles.toast
            )}
          >
            <div>
              <div className="font-bold">{notification.subject}</div>
              <div className="text-sm">{formatDate(notification.date)}</div>
              <div className="mt-1 text-sm">
                <Link
                  to={
                    Path.USER_NOTIFICATIONS +
                    `?id=${notification.notification_id}`
                  }
                >
                  <span className="text-blue-400">Abrir</span>
                </Link>
                <button
                  className="focus:outline-none"
                  onClick={() => props.onRead(notification.notification_id)}
                >
                  <span
                    className={classNames('ml-4', {
                      'text-blue-400': !props.readIds.includes(
                        notification.notification_id
                      ),
                      'text-gray-400': props.readIds.includes(
                        notification.notification_id
                      ),
                    })}
                  >
                    Marcar como lida
                  </span>
                </button>
              </div>
            </div>
            <button
              className="focus:outline-none"
              onClick={() => props.onClose(notification.notification_id)}
            >
              <XIcon className="h-5 w-5" />
            </button>
          </div>
        ))}
    </div>
  )
}

export default Notifications
