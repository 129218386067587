import { css } from '@emotion/css'

import datawiseLogo from '../assets/datawise-logo.png'

export default () => (
  <img
    className={css`
      margin-left: 1rem;
      margin-top: 0.25rem;
      height: 2rem;
    `}
    src={datawiseLogo}
    alt="logo"
  />
)
