import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { Link, useLocation } from 'react-router-dom'

import { useNotifications } from '../../contexts/NotificationsContext'
import { useUser } from '../../contexts/UserContext'
import { Path } from '../../utils/constants'

interface Props {
  className?: string
}

const MENU_BUTTON_CLASS =
  'w-full px-4 py-2 text-left border-b hover:bg-gray-100 focus:outline-none'

const UserAvatar: React.FC<Props> = (props) => {
  const { email, logout, checkPermissions } = useUser()
  const { unreadNotifications } = useNotifications()
  const { pathname } = useLocation()

  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  useEffect(() => {
    setMenuOpen(false)
  }, [pathname])

  const initials = email?.slice(0, 2).toLocaleUpperCase() ?? ''

  return email ? (
    <OutsideClickHandler onOutsideClick={() => setMenuOpen(false)}>
      <div className={classNames('relative', props.className)}>
        <button
          className="relative h-10 w-10 rounded-full bg-primary-main text-white outline-none ring-white ring-opacity-20 hover:ring-2"
          onClick={() => setMenuOpen((cur) => !cur)}
        >
          {initials}
          {unreadNotifications.length > 0 && (
            <span className="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-500" />
          )}
        </button>
        {menuOpen && (
          <div className="absolute bottom-0 right-0 z-20 translate-y-full transform">
            <div className="mt-1 flex w-64 flex-col items-stretch rounded-b-sm bg-white shadow-md">
              <div className="flex items-center justify-center border-b p-2 text-gray-400 hover:bg-gray-100">
                {email}
              </div>
              <Link to={Path.USER_DASHBOARD}>
                <button className={MENU_BUTTON_CLASS}>Dashboard</button>
              </Link>
              <Link to={Path.USER_NOTIFICATIONS}>
                <button
                  className={classNames(
                    'w-full border-b px-4 py-2 hover:bg-gray-100 focus:outline-none',
                    {
                      'flex items-center justify-between':
                        unreadNotifications.length > 0,
                      'text-left': unreadNotifications.length === 0,
                    }
                  )}
                >
                  <span>Notificações</span>
                  {unreadNotifications.length > 0 && (
                    <span className="flex h-6 w-6 items-center justify-center rounded-full bg-red-500 text-sm text-white">
                      {unreadNotifications.length}
                    </span>
                  )}
                </button>
              </Link>
              {checkPermissions(['v2:compass:equity:baskets']) && (
                <Link to={Path.BASKETS}>
                  <button className={MENU_BUTTON_CLASS}>Baskets manager</button>
                </Link>
              )}
              {checkPermissions([
                'v2:compass:equity:classification_manager',
              ]) && (
                <Link to={Path.CLASSIFICATION_MANAGER}>
                  <button className={MENU_BUTTON_CLASS}>
                    Classification manager
                  </button>
                </Link>
              )}
              {checkPermissions(['v2:compass:notifications:manager']) && (
                <Link to={Path.NOTIFICATIONS_MANAGER}>
                  <button className={MENU_BUTTON_CLASS}>
                    Notifications Manager
                  </button>
                </Link>
              )}
              <button
                className="px-4 py-2 text-left hover:bg-gray-100 focus:outline-none"
                onClick={() => logout()}
              >
                Sair
              </button>
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  ) : null
}

export default UserAvatar
