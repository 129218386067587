import classNames from 'classnames'
import React from 'react'

interface Props {
  className?: string
  position?: 'fixed' | 'absolute'
  color?: 'white' | 'gray'
}

const OpaqueBackdrop: React.FC<Props> = (props) => {
  const position = props.position ?? 'fixed'
  const color = props.color ?? 'white'

  return (
    <div
      className={classNames(
        'inset-0 z-50',
        position,
        {
          'bg-white/50': color === 'white',
          'bg-gray-100/50': color === 'gray',
        },
        props.className
      )}
    >
      {props.children}
    </div>
  )
}

export default OpaqueBackdrop
