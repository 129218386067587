import type { ChartsTheme } from '../../types/charts'

export const chartsTheme: ChartsTheme = {
  palette: [
    '#456E9D',
    '#F18326',
    '#509745',
    '#AD8E28',
    '#DE4D4F',
    '#96C4E5',
    '#FFB672',
    '#81CB72',
    '#EFC758',
    '#F77070',
    '#456E9D',
    '#F18326',
  ],
}
