import type { Theme } from '../types'
import * as datawise from './datawise'
import * as jgp from './jgp'
import * as whitelabel from './whitelabel'

export type ThemeID = typeof THEME_IDS[number]

const THEME_IDS = [
  datawise.THEME_ID,
  jgp.THEME_ID,
  whitelabel.THEME_ID,
] as const

const cache: Record<string, Theme> = {}

const buildTheme = async (id: ThemeID): Promise<Theme> => {
  switch (id) {
    case datawise.THEME_ID:
      return datawise.buildTheme()

    case jgp.THEME_ID:
      return jgp.buildTheme()

    case whitelabel.THEME_ID:
      return whitelabel.buildTheme()

    default:
      throw new Error(`Unrecognized theme id "${id}"`)
  }
}

export const getTheme = async (theme: ThemeID): Promise<Theme> => {
  if (!cache[theme]) {
    cache[theme] = await buildTheme(theme)
  }

  return cache[theme]
}
