import type { ChartsTheme } from '../../types/charts'

export const chartsTheme: ChartsTheme = {
  palette: [
    '#25C8EB',
    '#25EEB1',
    '#80FF9D',
    '#ADFFA7',
    '#D6FFB0',
    '#FCFFD1',
    '#25C8EB',
    '#25EEB1',
    '#80FF9D',
    '#ADFFA7',
    '#D6FFB0',
    '#FCFFD1',
  ],
}
