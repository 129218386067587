import type { ChartConfig } from '@jgp-er-dev/chart-utils'
import type { Customizations } from '@jgp-er-dev/charts'
import type { ThemeID } from '@jgp-er-dev/themes'

import { makeRequest } from '../'

const LOGIN_PATH = '/api/auth/login'
const LOGOUT_PATH = '/api/auth/logout'
export const USER_PATH = '/api/user'
const VERIFY_EMAIL_PATH = '/api/user/verifyemail'
const COMPLETE_REGISTRATION_PATH = '/api/auth/register'
const CHART_CUSTOMIZATIONS_PATH = '/api/user/chart-customizations'
const SHARED_CHARTS_PATH = '/api/charts/share'
const SHARED_DATA_PATH = '/api/share'

const SINGLE_FLIGHT_TIMEOUT = 50

export type StatusFlag = 'red' | 'yellow' | 'green'

export interface DataSource {
  text: string
  href: string
  last_capture: string | null
  status_flag?: StatusFlag
}

export interface CompassAPIResponse<DataT> {
  count: number
  data_sources: DataSource[] | null
  data: DataT
  title?: string
  reference_date?: string
  subtitle?: string
}

export const login = async (
  user: string,
  pwd: string,
  remember_me: boolean
): Promise<void> => {
  await makeRequest({
    url: LOGIN_PATH,
    method: 'POST',
    data: { user, pwd, remember_me },
  })
}

export const logout = async (): Promise<void> => {
  await makeRequest({
    url: LOGOUT_PATH,
    method: 'POST',
  })
}

export const getUser = async (): Promise<{
  email: string
  name: string
  theme: ThemeID
  permissions: string[]
}> => {
  const response = await makeRequest({
    url: USER_PATH,
    method: 'GET',
  })
  return response.data
}

export const sendVerificationEmail = async (email: string): Promise<void> => {
  await makeRequest({
    url: VERIFY_EMAIL_PATH,
    method: 'POST',
    data: { email },
  })
}

export const completeRegistration = async (
  email: string,
  pwd: string,
  token: string
): Promise<void> => {
  await makeRequest({
    url: COMPLETE_REGISTRATION_PATH,
    method: 'POST',
    data: { email, pwd, token },
  })
}

export const getSharedChart = async (
  id: string
): Promise<{ chart: ChartConfig; customizations: Customizations }> => {
  const response = await makeRequest({
    url: SHARED_CHARTS_PATH,
    method: 'GET',
    params: { id },
  })
  return response.data
}

export const createSharedChart = async (
  config: ChartConfig,
  customizations: Customizations
): Promise<{ id: string }> => {
  const response = await makeRequest({
    url: SHARED_CHARTS_PATH,
    method: 'POST',
    data: {
      chart: config,
      customizations,
    },
  })
  return response.data
}

// Bundles multiple consecutive calls into a single request
let getChartCustomizationBatchQueue: {
  resolve: (result: Customizations | PromiseLike<Customizations>) => void
  reject: (reason?: any) => void
  chartId: string
}[] = []
let getChartCustomizationBatchTimeout: any = null
export const getChartCustomization = async (
  chartId: string
): Promise<Customizations> => {
  if (getChartCustomizationBatchTimeout) {
    clearTimeout(getChartCustomizationBatchTimeout)
  }

  const promise = new Promise<Customizations>((resolve, reject) => {
    getChartCustomizationBatchQueue.push({
      resolve,
      reject,
      chartId,
    })
  })

  getChartCustomizationBatchTimeout = setTimeout(() => {
    const curQueue = getChartCustomizationBatchQueue

    makeRequest(
      {
        url: CHART_CUSTOMIZATIONS_PATH,
        method: 'GET',
        params: {
          chartId: curQueue.map(({ chartId }) => chartId),
        },
      },
      { priority: 'high' }
    )
      .then((response) => {
        curQueue.forEach(({ resolve, chartId }) =>
          resolve(response.data[chartId])
        )
      })
      .catch((err) => {
        curQueue.forEach(({ reject }) => reject(err))
      })

    getChartCustomizationBatchQueue = []
  }, SINGLE_FLIGHT_TIMEOUT)

  return promise
}

export const saveChartCustomization = async (
  chartId: string,
  customizations: Customizations
): Promise<void> => {
  await makeRequest(
    {
      url: CHART_CUSTOMIZATIONS_PATH,
      method: 'POST',
      data: {
        chartId,
        customizations,
      },
    },
    { priority: 'urgent' }
  )
}

export const getSharedData = async (id: string): Promise<any> => {
  const response = await makeRequest({
    url: SHARED_DATA_PATH,
    method: 'GET',
    params: { id },
  })
  return response.data
}

export const createSharedData = async (data: any): Promise<string> => {
  const response = await makeRequest({
    url: SHARED_DATA_PATH,
    method: 'POST',
    data,
  })
  return response.data.id
}
