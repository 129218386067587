import { ExportContextProvider } from '@jgp-er-dev/charts'
import React, {
  lazy,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom'

import { getMenuList } from './clients/compass/menu'
import LoadingContainer from './components/LoadingContainer'
import OpaqueBackdrop from './components/OpaqueBackdrop'
import SideBar from './components/SideBar'
import { SideBarTreeSection } from './components/SideBar'
import Spinner from './components/Spinner'
import TopBar from './components/TopBar'
import { ModalContextProvider } from './contexts/ModalContext'
import { NotificationsContextProvider } from './contexts/NotificationsContext'
import { ToastContextProvider } from './contexts/ToastContext'
import { UserContextProvider, useUser } from './contexts/UserContext'
import { Path } from './utils/constants'
import { sideBarTree as staticSideBarTree } from './utils/constants'
import { PrioritizablePromise } from './utils/promise-queue'
import version from './version.json'

const AnnotationPage = lazy(() => import('./pages/annotation/pages'))
const BasketsPage = lazy(() => import('./pages/baskets/pages'))
const ESGPage = lazy(() => import('./pages/esg/pages'))
const LaunchpadPage = lazy(() => import('./pages/launchpad/pages'))
const LoginPage = lazy(() => import('./pages/login'))
const NotFoundPage = lazy(() => import('./pages/not-found'))
const PortfolioPage = lazy(() => import('./pages/portfolio/pages'))
const PresentationsPage = lazy(() => import('./pages/presentations/pages'))
const RegisterPage = lazy(() => import('./pages/register/pages'))
const ReportManagerPage = lazy(() => import('./pages/report-manager/pages'))
const ReportsPage = lazy(() => import('./pages/reports/pages'))
const ResetPasswordPage = lazy(() => import('./pages/resetpassword'))
const SearchPage = lazy(() => import('./pages/search/pages'))
const SharedChartPage = lazy(() => import('./pages/sharedchart/pages'))
const TestPage = lazy(() => import('./pages/test/pages'))
const UpdateSearchPage = lazy(() => import('./pages/update-search'))
const UserPage = lazy(() => import('./pages/user/pages'))
const ValuationPage = lazy(() => import('./pages/valuation/pages'))

const useSideBarTree = () => {
  const { email } = useUser()

  const [v1MenuList, setV1MenuList] = useState<SideBarTreeSection[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const menuListPromiseRef = useRef<PrioritizablePromise<any> | null>(null)

  useEffect(() => {
    if (!email) {
      return
    }

    setLoading(true)
    menuListPromiseRef.current = getMenuList(email)
      .thenP((res) => setV1MenuList(res))
      .catchP((err) => console.error('Error fetching menu list: ', err))
      .finallyP(() => setLoading(false))
  }, [email])

  const sideBarTree = useMemo(
    () => ({
      title: staticSideBarTree.title,
      children: [
        ...staticSideBarTree.children,
        ...(v1MenuList.length > 0 ? v1MenuList : []),
      ],
    }),
    [v1MenuList]
  )

  return {
    loading,
    sideBarTree,
  }
}

const RedirectToLogin: React.FC = () => {
  const { pathname, search } = useLocation()

  return (
    <Redirect
      to={{
        pathname: Path.LOGIN,
        search: `?redirect=${encodeURIComponent(pathname + search)}`,
      }}
    />
  )
}

const LoadingFallback = () => (
  <OpaqueBackdrop position="fixed" color="white">
    <LoadingContainer loading spinnerSize={48} />
  </OpaqueBackdrop>
)

const App: React.FC = () => {
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false)
  const { checkPermissions, email, loading, isWhitelabelUser, theme } =
    useUser()
  const { loading: sideBarLoading, sideBarTree } = useSideBarTree()

  useEffect(() => {
    ;(window as any).jgp = { version: version.version }
  })

  return loading || sideBarLoading ? (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-100">
      <Spinner className="text-success-dark" size={64} />
    </div>
  ) : !email ? (
    <Switch>
      <Route path={Path.LOGIN} exact>
        <Suspense fallback={<LoadingFallback />}>
          <LoginPage />
        </Suspense>
      </Route>
      <Route path={Path.REGISTER}>
        <Suspense fallback={<LoadingFallback />}>
          <RegisterPage />
        </Suspense>
      </Route>
      <Route path={Path.RESET_PASSWORD}>
        <Suspense fallback={<LoadingFallback />}>
          <ResetPasswordPage />
        </Suspense>
      </Route>
      <Route>
        <RedirectToLogin />
      </Route>
    </Switch>
  ) : (
    <ExportContextProvider className="z-50" theme={theme!}>
      <div className="relative flex h-screen min-h-full w-full min-w-min flex-col bg-gray-100 font-work-sans">
        <SideBar
          open={sideBarOpen}
          tree={sideBarTree}
          checkPermissions={checkPermissions}
          display={isWhitelabelUser ? 'whitelabel' : 'default'}
          onClose={() => setSideBarOpen(false)}
        />
        <TopBar
          className="flex-shrink-0"
          onMenuOpen={() => setSideBarOpen(true)}
        />
        <Switch>
          <Route path={Path.ANNOTATION}>
            <Suspense fallback={<LoadingFallback />}>
              <AnnotationPage />
            </Suspense>
          </Route>
          <Route path={Path.BASKETS}>
            <Suspense fallback={<LoadingFallback />}>
              <BasketsPage />
            </Suspense>
          </Route>
          <Route path={Path.ESG}>
            <Suspense fallback={<LoadingFallback />}>
              <ESGPage />
            </Suspense>
          </Route>
          <Route path={Path.HOME} exact>
            <Redirect to={Path.ESG_FRAMEWORK_MANAGER} />
          </Route>
          <Route path={Path.LAUNCHPAD}>
            <Suspense fallback={<LoadingFallback />}>
              <LaunchpadPage />
            </Suspense>
          </Route>
          <Route path={Path.LOGIN} exact>
            <Redirect to={Path.HOME} />
          </Route>
          <Route path={Path.PORTFOLIO}>
            <Suspense fallback={<LoadingFallback />}>
              <PortfolioPage />
            </Suspense>
          </Route>
          <Route path={Path.PRESENTATIONS}>
            <Suspense fallback={<LoadingFallback />}>
              <PresentationsPage />
            </Suspense>
          </Route>
          <Route path={Path.REGISTER}>
            <Suspense fallback={<LoadingFallback />}>
              <RegisterPage />
            </Suspense>
          </Route>
          <Route path={Path.REPORTS}>
            <Suspense fallback={<LoadingFallback />}>
              <ReportsPage />
            </Suspense>
          </Route>
          <Route path={Path.REPORT_MANAGER}>
            <Suspense fallback={<LoadingFallback />}>
              <ReportManagerPage />
            </Suspense>
          </Route>
          <Route path={Path.RESET_PASSWORD}>
            <Suspense fallback={<LoadingFallback />}>
              <ResetPasswordPage />
            </Suspense>
          </Route>
          <Route path={Path.SEARCH}>
            <Suspense fallback={<LoadingFallback />}>
              <SearchPage />
            </Suspense>
          </Route>
          <Route path={Path.SHARED_CHART}>
            <Suspense fallback={<LoadingFallback />}>
              <SharedChartPage />
            </Suspense>
          </Route>
          <Route path={Path.TEST}>
            <Suspense fallback={<LoadingFallback />}>
              <TestPage />
            </Suspense>
          </Route>
          <Route path={Path.UPDATE_SEARCH}>
            <Suspense fallback={<LoadingFallback />}>
              <UpdateSearchPage />
            </Suspense>
          </Route>
          <Route path={Path.USER}>
            <Suspense fallback={<LoadingFallback />}>
              <UserPage />
            </Suspense>
          </Route>
          <Route path={Path.VALUATION}>
            <Suspense fallback={<LoadingFallback />}>
              <ValuationPage />
            </Suspense>
          </Route>
          <Route>
            <Suspense fallback={<LoadingFallback />}>
              <NotFoundPage />
            </Suspense>
          </Route>
        </Switch>
      </div>
    </ExportContextProvider>
  )
}

const EnhancedApp: React.FC = () => (
  <Router>
    <UserContextProvider>
      <ToastContextProvider>
        <ModalContextProvider>
          <NotificationsContextProvider>
            <App />
          </NotificationsContextProvider>
        </ModalContextProvider>
      </ToastContextProvider>
    </UserContextProvider>
  </Router>
)

export default EnhancedApp
