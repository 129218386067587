import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

interface Props extends Omit<React.InputHTMLAttributes<any>, 'onChange'> {
  onChange?: (newValue: string) => void
}

const Input: React.FC<Props> = (props) => {
  const [value, setValue] = useState<string>((props.value as string) ?? '')

  useEffect(() => {
    setValue((props.value as string) ?? '')
  }, [props.value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event.target.value)
    } else if (props.value === undefined) {
      setValue(event.target.value)
    }
  }

  return (
    <input
      {...props}
      value={value}
      onChange={handleChange}
      className={classNames(
        'rounded-sm px-2 focus:outline-none',
        props.className
      )}
    />
  )
}

export default Input
