import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import Toast from '../../components/Toast'

interface IToast {
  message: string
  type?: 'success' | 'fail' | 'info'
  autoDismissTime?: number
}

interface IToastContext {
  enqueueToast: (toast: IToast) => void
}

const ToastContext = createContext<IToastContext>({} as IToastContext)

export const ToastContextProvider: React.FC = (props) => {
  const [open, setOpen] = useState<boolean>(false)
  const [queue, setQueue] = useState<IToast[]>([])
  const [toast, setToast] = useState<IToast | null>(null)

  useEffect(() => {
    if (queue.length > 0 && !toast) {
      setToast(queue[0])
      setQueue((q) => q.slice(1))
      setOpen(true)
    } else if (queue.length > 0 && toast && open) {
      setOpen(false)
    }
  }, [queue, toast, open])

  const enqueueToast = useCallback(
    (newToast: IToast) => setQueue((q) => q.concat(newToast)),
    []
  )

  const value = useMemo(() => ({ enqueueToast }), [enqueueToast])

  return (
    <ToastContext.Provider value={value}>
      {props.children}
      <Toast
        open={open}
        onClose={() => setOpen(false)}
        onExit={() => setToast(null)}
        message={toast?.message ?? ''}
        type={toast?.type}
        autoDismissTime={toast?.autoDismissTime}
      />
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext)
