import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import Modal from '../../components/Modal'

interface IModalContext {
  openModal: (args: {
    content: JSX.Element
    actions: {
      label: string
      className: string
      handler: () => void
    }[]
    dismissable?: boolean
  }) => void
}

const ModalContext = createContext<IModalContext>({} as IModalContext)

export const ModalContextProvider: React.FC = (props) => {
  const [open, setOpen] = useState<boolean>(false)
  const [content, setContent] = useState<JSX.Element | null>(null)
  const [actions, setActions] = useState<
    { label: string; className: string; handler: () => void }[]
  >([])
  const [dismissable, setDismissable] = useState<boolean>(false)

  const openModal: IModalContext['openModal'] = useCallback(
    ({ content, actions, dismissable }) => {
      setContent(content)
      setActions(actions)
      setDismissable(dismissable ?? true)
      setOpen(true)
    },
    []
  )

  const value = useMemo(() => ({ openModal }), [openModal])

  return (
    <ModalContext.Provider value={value}>
      {props.children}
      <Modal
        open={open}
        content={content}
        actions={actions}
        onClose={() => setOpen(false)}
        dismissable={dismissable}
      />
    </ModalContext.Provider>
  )
}

export const useModal = () => useContext(ModalContext)
