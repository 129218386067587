import axios, { AxiosRequestConfig } from 'axios'

import { enqueuePromise, makePrioritizable } from '../utils/promise-queue'

export type RequestPriority = 'default' | 'high' | 'urgent'

interface MakeRequestOptions {
  /**
   * Determines the moment this request should be run.
   * - default: Request is sent to the back of the request queue
   * - high: Request is sent to the front of the request queue
   * - urgent: Request is run immediately
   */
  priority?: RequestPriority
}

const DEFAULT_TIMEOUT = 30000

export const makeRequest = (
  config: Partial<AxiosRequestConfig>,
  options?: MakeRequestOptions
) => {
  const request = {
    timeout: DEFAULT_TIMEOUT,
    withCredentials: true,
    ...config,
  } as AxiosRequestConfig

  if (options?.priority === 'urgent') {
    return makePrioritizable(axios(request), () => {})
  }

  return enqueuePromise(() => axios(request), {
    prioritize: options?.priority === 'high',
  })
}
