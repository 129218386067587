import type { SearchableElement } from '../../utils/search'
import { makeRequest } from '../'

export const getSearchHits = async (
  query: string,
  options?: {
    limit?: number
  }
): Promise<SearchableElement[]> => {
  const response = await makeRequest({
    url: '/api/search',
    method: 'GET',
    params: {
      q: query,
      ...(options?.limit ? { limit: options.limit } : {}),
    },
  })

  return response.data
}
