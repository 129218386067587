import React from 'react'

const HierarchyIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          d="M496,336h-32v-80c0-8.832-7.168-16-16-16H272v-64h32c8.832,0,16-7.168,16-16V64c0-8.832-7.168-16-16-16h-96
		c-8.832,0-16,7.168-16,16v96c0,8.832,7.168,16,16,16h32v64H64c-8.832,0-16,7.168-16,16v80H16c-8.832,0-16,7.168-16,16v96
		c0,8.832,7.168,16,16,16h96c8.832,0,16-7.168,16-16v-96c0-8.832-7.168-16-16-16H80v-64h160v64h-32c-8.832,0-16,7.168-16,16v96
		c0,8.832,7.168,16,16,16h96c8.832,0,16-7.168,16-16v-96c0-8.832-7.168-16-16-16h-32v-64h160v64h-32c-8.832,0-16,7.168-16,16v96
		c0,8.832,7.168,16,16,16h96c8.832,0,16-7.168,16-16v-96C512,343.168,504.832,336,496,336z"
        />
      </g>
    </g>
  </svg>
)

export default HierarchyIcon
