// Adapted from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/CircularProgress/CircularProgress.js

import classNames from 'classnames'
import React from 'react'

import styles from './Spinner.module.css'

export interface Props {
  size?: number
  className?: string
}

const Spinner: React.FC<Props> = (props) => {
  const size = props.size ?? 40
  const CIRCLE_SIZE = 44
  const THICKNESS = 3.6

  return (
    <span
      className={classNames(styles.container, props.className)}
      style={{ width: size, height: size }}
      role="progressbar"
    >
      <svg
        className="block"
        viewBox={`${CIRCLE_SIZE / 2} ${
          CIRCLE_SIZE / 2
        } ${CIRCLE_SIZE} ${CIRCLE_SIZE}`}
      >
        <circle
          className={styles.circle}
          cx={CIRCLE_SIZE}
          cy={CIRCLE_SIZE}
          r={(CIRCLE_SIZE - THICKNESS) / 2}
          fill="none"
          strokeWidth={THICKNESS}
        />
      </svg>
    </span>
  )
}

export default Spinner
