import { move } from 'ramda'
import { v4 as uuid } from 'uuid'

interface QueueElement {
  id: string
  resolve: (result: any) => void
  reject: (reason?: any) => void
  run: () => Promise<any>
}

interface EnqueuePromiseOptions {
  prioritize?: boolean
}

type CallbackType = 'empty' | 'enqueue'

const MAX_CONCURRENT_PROMISES = 5
const IDLE_TIMEOUT_MS = 200

let queue: QueueElement[] = []
let ongoingPromiseCount = 0

export class PrioritizablePromise<T> extends Promise<T> {
  constructor(
    executor: (
      resolve: (value: T | PromiseLike<T>) => void,
      reject: (reason?: any) => void
    ) => void,
    onPrioritize?: () => void
  ) {
    super(executor)
    this.prioritize = onPrioritize ?? (() => {})
  }

  prioritize: () => void

  thenP: <U>(f: (res: T) => U) => PrioritizablePromise<U> = (f) =>
    makePrioritizable(this.then(f), this.prioritize)

  catchP: <U>(f: (err: any) => U) => PrioritizablePromise<T | U> = (f) =>
    makePrioritizable(this.catch(f), this.prioritize)

  finallyP: (f: () => void) => PrioritizablePromise<T> = (f) =>
    makePrioritizable(this.finally(f), this.prioritize)
}

export const makePrioritizable = <T>(
  promise: Promise<T>,
  onPrioritize: () => void
): PrioritizablePromise<T> => {
  return new PrioritizablePromise((resolve, reject) => {
    promise.then(resolve).catch(reject)
  }, onPrioritize)
}

export const enqueuePromise = <T>(
  run: () => Promise<T>,
  options?: EnqueuePromiseOptions
) => {
  callCallbacks('enqueue')

  if (ongoingPromiseCount < MAX_CONCURRENT_PROMISES) {
    ongoingPromiseCount += 1
    return makePrioritizable(run().finally(handlePromiseFinished), () => {})
  } else {
    const id = uuid()
    return new PrioritizablePromise<T>(
      (resolve, reject) => {
        const enqueuedPromise = {
          id,
          resolve,
          reject,
          run,
        }

        if (options?.prioritize) {
          queue.unshift(enqueuedPromise)
        } else {
          queue.push(enqueuedPromise)
        }
      },
      () => prioritizePromise(id)
    )
  }
}

let callbacks: { id: string; type: CallbackType; fn: () => any }[] = []

export const registerCallback = (type: CallbackType, fn: () => any) => {
  const id = uuid()
  callbacks.push({ id, type, fn })
  return id
}

export const unregisterCallback = (id: string) =>
  (callbacks = callbacks.filter((cb) => cb.id !== id))

const callCallbacks = (type: CallbackType) =>
  callbacks.filter((cb) => cb.type === type).forEach((cb) => cb.fn())

const handlePromiseFinished = () => {
  ongoingPromiseCount -= 1
  if (queue.length > 0) {
    const promise = queue.shift()!
    ongoingPromiseCount += 1
    promise
      .run()
      .then((data) => promise.resolve(data))
      .catch((err) => promise.reject(err))
      .finally(handlePromiseFinished)
  } else {
    if (ongoingPromiseCount === 0) {
      callCallbacks('empty')
    }
  }
}

const prioritizePromise = (id: string) => {
  const idx = queue.findIndex((el) => el.id === id)
  if (idx === -1) {
    return
  }
  queue = move(idx, 0, queue)
}

export const waitForIdle = async () =>
  new Promise<void>((resolve) => {
    let enqueueCbId: string | null = null
    let emptyCbId: string | null = null

    const cleanUp = () => {
      enqueueCbId && unregisterCallback(enqueueCbId)
      emptyCbId && unregisterCallback(emptyCbId)
      resolve()
    }

    let timeout =
      ongoingPromiseCount > 0 ? null : setTimeout(cleanUp, IDLE_TIMEOUT_MS)

    enqueueCbId = registerCallback('enqueue', () => {
      timeout && clearTimeout(timeout)
    })

    emptyCbId = registerCallback('empty', () => {
      timeout = setTimeout(cleanUp, IDLE_TIMEOUT_MS)
    })
  })
