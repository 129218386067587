export const palette = {
  primary: {
    light: '#38387c',
    main: '#1a1a66',
    dark: '#16164d',
  },
  secondary: {
    light: '#c3c6b9',
    main: '#a8ab9b',
    dark: '#7e7e74',
  },
  error: {
    light: '#f4643d',
    main: '#ea5819',
    dark: '#e04f00',
  },
  warning: {
    light: '#ffd05a',
    main: '#ffcb0d',
    dark: '#e5ae00',
  },
  info: {
    light: '#333333',
    main: '#2b2b2b',
    dark: '#000000',
  },
  success: {
    light: '#56cc2b',
    main: '#30ba09',
    dark: '#297f0d',
  },
}
