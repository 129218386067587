import type { PermissionName } from '@jgp-er-dev/permissions'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'

import { useUser } from '../../contexts/UserContext'
import type { DisplayType } from '../../pages/portfolio/utils/constants'
import MenuIcon from '../icons/Menu'
import Input from '../Input'
import Scrollbar from '../Scrollbar'
import { NodeLink } from './NodeLink'

interface Props {
  checkPermissions: (permissions: PermissionName[]) => boolean
  className?: string
  display: DisplayType
  tree: SideBarTreeSection
  open: boolean
  onClose: () => void
}

export interface SideBarTreeSection {
  title: string
  children: (SideBarTreeSection | SideBarTreeLink)[]
  permissions?: PermissionName[]
}

interface SideBarTreeLink {
  title: string
  path: string
  permissions?: PermissionName[]
}

type SideBarTreeNode = SideBarTreeSection | SideBarTreeLink

interface SideBarTreeSectionWithParent extends SideBarTreeSection {
  children: (SideBarTreeSectionWithParent | SideBarTreeLink)[]
  parent: SideBarTreeSectionWithParent | null
}

interface SearchHit {
  title: string
  path: string
  sectionPath: string
}

const isLink = (node: SideBarTreeNode): node is SideBarTreeLink => {
  return node.hasOwnProperty('path')
}

const processTree = (
  tree: SideBarTreeNode,
  parent: SideBarTreeSectionWithParent | null = null
) => {
  if (isLink(tree)) {
    return {
      title: tree.title,
      path: tree.path,
      permissions: tree.permissions,
      parent,
    } as SideBarTreeLink
  }

  const node: SideBarTreeSectionWithParent = {
    title: tree.title,
    permissions: tree.permissions,
    children: [],
    parent,
  }

  node.children = tree.children.map((child) => processTree(child, node))

  return node
}

const SideBar: React.FC<Props> = (props) => {
  const tree = useMemo(
    () => processTree(props.tree) as SideBarTreeSectionWithParent,
    [props.tree]
  )
  const [curNode, setCurNode] = useState<SideBarTreeSectionWithParent>(tree)
  const [menuAppearSide, setMenuAppearSide] = useState<'left' | 'right' | null>(
    null
  )
  const [closing, setClosing] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [curDepth, setCurDepth] = useState<number>(0)

  const { theme } = useUser()

  const buttonClass = classNames(
    theme?.compass.sideBar.listItemBackgroundColor ??
      'hover:bg-success-dark focus:bg-success-dark',
    'focus:outline-none'
  )

  const handleClose = () => {
    setMenuAppearSide(null)
    setClosing(true)
    setTimeout(() => {
      setClosing(false)
      props.onClose?.()
    }, 200)
  }

  const handleBack = () => {
    setCurDepth((depth) => depth - 1)
    setMenuAppearSide('left')
    setCurNode(curNode.parent!)
  }

  const searchResults = useMemo(() => {
    const results: SearchHit[] = []

    const dfs = (node: SideBarTreeNode, path: string[] = []) => {
      if (isLink(node)) {
        if (
          node.title
            .toLocaleLowerCase()
            .includes(searchQuery.toLocaleLowerCase())
        ) {
          results.push({
            title: node.title,
            path: node.path,
            sectionPath: path
              .slice(1)
              .map((title) => `${title} >`)
              .join(' '),
          })
        }
        return
      }

      node.children.forEach((child) => dfs(child, path.concat(node.title)))
    }

    dfs(tree)

    return results
  }, [tree, searchQuery])

  return (
    <div
      className={classNames('fixed inset-0 z-20', {
        hidden: !props.open,
        'bg-black bg-opacity-25': props.open && !closing,
      })}
      onClick={handleClose}
    >
      <div
        className={classNames(
          'absolute left-0 flex h-full min-h-screen w-72 animate-translate-from-left flex-col overflow-hidden pr-2 pb-4 text-lg text-white',
          theme?.compass.sideBar.backgroundColor,
          {
            '-translate-x-full transform transition-transform duration-200':
              closing,
          }
        )}
        onClick={(event) => event.stopPropagation()}
      >
        <div className="flex h-12 flex-shrink-0 items-center px-4">
          <button
            className="bg-white bg-opacity-0 hover:bg-opacity-10 focus:outline-none focus-visible:bg-opacity-10"
            onClick={handleClose}
          >
            <MenuIcon className="h-8 text-white" />
          </button>
          {theme && <theme.compass.sideBar.Logo />}
        </div>
        <div className="my-2 w-full px-4">
          <Input
            className={classNames(
              'w-full',
              theme?.compass.sideBar.searchInputBackgroundColor ??
                'bg-info-light'
            )}
            placeholder="Buscar"
            value={searchQuery}
            onChange={(newQuery) => setSearchQuery(newQuery)}
          />
        </div>
        <Scrollbar className="mt-4 flex-grow">
          <div
            key={curDepth}
            className={classNames('flex flex-col items-start', {
              'animate-translate-from-left': menuAppearSide === 'left',
              'animate-translate-from-right': menuAppearSide === 'right',
            })}
          >
            {searchQuery ? (
              searchResults.length === 0 ? (
                <div className="px-4 text-base">
                  Nenhum resultado foi encontrado
                </div>
              ) : (
                searchResults.map((searchHit, index) => (
                  <NodeLink
                    key={index}
                    className={classNames('w-full px-4 py-2', buttonClass)}
                    to={searchHit.path}
                  >
                    {searchHit.sectionPath && (
                      <div className="text-left text-sm">
                        {searchHit.sectionPath}
                      </div>
                    )}
                    <div className="text-left">{searchHit.title}</div>
                  </NodeLink>
                ))
              )
            ) : (
              <>
                {curNode.title && (
                  <div
                    className={classNames(
                      'mb-4 flex h-14 items-center pr-4 font-bold',
                      {
                        'pl-4': !curNode.parent,
                      }
                    )}
                  >
                    {curNode.parent !== null && (
                      <button
                        className={classNames(
                          'mr-2 px-4 py-2 text-left',
                          buttonClass
                        )}
                        onClick={handleBack}
                      >
                        {'<'}
                      </button>
                    )}
                    {curNode.title}
                  </div>
                )}
                {curNode.children.map((child) =>
                  !props.checkPermissions(
                    child.permissions ?? []
                  ) ? null : isLink(child) ? (
                    <NodeLink
                      key={child.title}
                      className={classNames(
                        'w-full px-4 py-2 text-left',
                        buttonClass
                      )}
                      to={child.path}
                    >
                      {child.title}
                    </NodeLink>
                  ) : (
                    <button
                      key={child.title}
                      className={classNames(
                        'flex w-full items-center justify-between px-4 py-2',
                        buttonClass
                      )}
                      onClick={() => {
                        setCurDepth((depth) => depth + 1)
                        setMenuAppearSide('right')
                        setCurNode(child)
                      }}
                    >
                      <span>{child.title}</span>
                      <span>{'>'}</span>
                    </button>
                  )
                )}
                {curNode.parent !== null && (
                  <button
                    className={classNames(
                      'mt-4 px-4 py-2 text-left',
                      buttonClass
                    )}
                    onClick={handleBack}
                  >
                    {'< Voltar'}
                  </button>
                )}
              </>
            )}
          </div>
        </Scrollbar>
      </div>
    </div>
  )
}

export default SideBar
