import { css } from '@emotion/css'

import jgpCompassLogo from '../assets/jgp-compass-logo.svg'

export default () => (
  <img
    className={css`
      margin-left: 1rem;
      margin-top: 0.25rem;
      height: 1.5rem;
    `}
    src={jgpCompassLogo}
    alt="logo"
  />
)
