import { css } from '@emotion/css'

import type { CompassTheme } from '../../types/compass'
import compassBackground from './assets/compass-background.png'
import jgpAssetLogo from './assets/jgp-asset-logo.png'
import jgpLogo from './assets/jgp-logo-black.png'
import CompassLogo from './components/CompassLogo'
import { palette } from './constants'

export const compassTheme: CompassTheme = {
  presentation: {
    assetLogoData: jgpAssetLogo,
    companyLogoData: jgpLogo,
    darkBackgroundClass: css`
      background-image: url(${compassBackground});
    `,
    darkBackgroundData: compassBackground,
    disclaimerText:
      'Este material foi elaborado pela JGP, tem caráter meramente informativo e não deve ser utilizado, distribuído ou copiado sem a prévia e expressa autorização da JGP.',
    summaryPageNumber: css`
      color: ${palette.success.dark};
    `,
    titleDateColor: palette.success.main,
  },
  scrollBar: {
    backgroundColor: css`
      background-color: #e0e0e0;
    `,
    barColor: css`
      background-color: ${palette.success.main};
    `,
  },
  sideBar: {
    backgroundColor: css`
      background-color: ${palette.info.dark};
    `,
    listItemBackgroundColor: css`
      :hover,
      :focus {
        background-color: ${palette.success.dark};
      }
    `,
    searchInputBackgroundColor: css`
      background-color: ${palette.info.light};
    `,
    Logo: CompassLogo,
  },
  topBar: {
    backgroundColor: css`
      background-color: ${palette.info.dark};
    `,
    searchInputBackgroundColor: css`
      background-color: ${palette.info.light};
    `,
    searchInputButtonColor: css`
      background-color: ${palette.info.dark};
      :hover,
      :focus {
        background-color: ${palette.info.main};
      }
    `,
    Logo: CompassLogo,
  },
}
