// Responsible for storing and manipulating Compass v2 user data and credentials.
// Do not store page-specific data here, create a new context for that purpose.

import type { PermissionName } from '@jgp-er-dev/permissions'
import type { Theme } from '@jgp-er-dev/themes'
import { getTheme } from '@jgp-er-dev/themes'
import { path } from 'ramda'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  getUser,
  login as _login,
  logout as _logout,
} from '../../clients/compass'

type LoginResult =
  | { success: true; errorMessage: undefined }
  | { success: false; errorMessage: string }

interface IUserContext {
  loading: boolean
  email: string | null
  name: string | null
  theme: Theme | null
  isWhitelabelUser: boolean
  checkPermissions: (permissions: PermissionName[]) => boolean
  login: (
    email: string,
    pwd: string,
    remember_me: boolean
  ) => Promise<LoginResult>
  logout: () => Promise<{ success: boolean }>
}

const UserContext = createContext<IUserContext>({} as IUserContext)

export const UserContextProvider: React.FC = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [email, setEmail] = useState<string | null>(null)
  const [name, setName] = useState<string | null>(null)
  const [theme, setTheme] = useState<Theme | null>(null)
  const [permissions, setPermissions] = useState<string[]>([])

  const verifyLogin = useCallback(() => {
    setLoading(true)
    getUser()
      .then(async ({ email, name, theme: themeId, permissions }) => {
        setEmail(email)
        setName(name)
        setPermissions(permissions)
        setTheme(await getTheme(themeId))
      })
      .catch(() => {
        setEmail(null)
        setName(null)
        setTheme(null)
        setPermissions([])
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(verifyLogin, [verifyLogin])

  const checkPermissions = useCallback(
    (list: PermissionName[]) => {
      if (!email) {
        return false
      }

      const result = list.every((permission) =>
        permissions.includes(permission)
      )
      return result
    },
    [email, permissions]
  )

  const login = useCallback(
    async (
      email: string,
      pwd: string,
      remember_me: boolean
    ): Promise<LoginResult> => {
      try {
        await _login(email, pwd, remember_me).then(verifyLogin)
        return { success: true, errorMessage: undefined }
      } catch (err) {
        const status = path(['response', 'status'], err)

        switch (status) {
          case 401:
            return {
              success: false,
              errorMessage: 'Incorrect email or password',
            }
          case 403:
            return {
              success: false,
              errorMessage:
                'You do not have permission to access this platform',
            }
          case 429:
            return {
              success: false,
              errorMessage: 'Too many requests. Please try again later',
            }
          default:
            throw err
        }
      }
    },
    [verifyLogin]
  )

  const logout = useCallback(async () => {
    try {
      await _logout()
      verifyLogin()
      return { success: true }
    } catch (err) {
      return { success: false }
    }
  }, [verifyLogin])

  const isWhitelabelUser = useMemo(
    () => !checkPermissions(['v2:jgp:mentions']),
    [checkPermissions]
  )

  const value = useMemo(
    () => ({
      loading,
      email,
      name,
      theme,
      isWhitelabelUser,
      checkPermissions,
      login,
      logout,
    }),
    [
      loading,
      email,
      name,
      theme,
      isWhitelabelUser,
      checkPermissions,
      login,
      logout,
    ]
  )

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
