import { SideBarTreeSection } from '../../components/SideBar'
import { COMPASS_V1_PATH } from '../../utils/constants'
import { PrioritizablePromise } from '../../utils/promise-queue'
import { makeRequest } from '../'

export interface MenuList {
  authorized: boolean
  children: MenuList[]
  description: string | null
  id: number
  label: string
  url: string | null
}

const USER_PATH = '/api/user'

const convertV1MenuListToTree = (list: MenuList[]): SideBarTreeSection[] =>
  list
    .filter(({ authorized }) => authorized)
    .map(
      ({ label, children, url }) =>
        (children.length === 0
          ? {
              title: label,
              path: COMPASS_V1_PATH + url,
            }
          : {
              title: label,
              children: convertV1MenuListToTree(children),
            }) as SideBarTreeSection
    )

export const getMenuList = (
  email: string
): PrioritizablePromise<SideBarTreeSection[]> =>
  makeRequest({
    url: USER_PATH + '/v1-menu-list',
    method: 'GET',
    params: { email },
  }).thenP((response) => convertV1MenuListToTree(response.data))
