import classNames from 'classnames'
import React from 'react'
import ReactDOM from 'react-dom'

interface Props {
  open: boolean
  content: JSX.Element | null
  actions: { label: string; className: string; handler: () => void }[]
  onClose: () => void
  removePadding?: boolean
  dismissable?: boolean
}

const Modal: React.FC<Props> = (props) => {
  if (!props.open) {
    return null
  }

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-25 p-4"
      onClick={() => props.dismissable && props.onClose()}
    >
      <div
        className={classNames(
          'flex max-h-full flex-col rounded-md bg-white shadow-xl',
          { 'px-8 pt-8 pb-4': !props.removePadding }
        )}
        onClick={(event) => event.stopPropagation()}
      >
        {props.content}
        {props.actions.length > 0 && (
          <div className="mt-4 flex flex-nowrap justify-end">
            {props.actions.map(({ label, className, handler }, index) => (
              <button
                className={className}
                key={index}
                onClick={() => {
                  handler()
                  props.onClose?.()
                }}
              >
                {label}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const ModalWithPortal = (props: Props) =>
  ReactDOM.createPortal(<Modal {...props} />, document.body)

export default ModalWithPortal
