import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  getNotifications,
  markNotificationsAsRead,
  Notification,
} from '../../clients/compass/notifications'
import { useUser } from '../UserContext'
import Notifications from './Notifications'

interface INotificationsContext {
  unreadNotifications: Notification[]
}

const NotificationsContext = createContext<INotificationsContext>(
  {} as INotificationsContext
)

const ONE_MINUTE_MS = 60 * 1000

export const NotificationsContextProvider: React.FC = (props) => {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [closedIds, setClosedIds] = useState<string[]>([])
  const [readIds, setReadIds] = useState<string[]>([])
  const { email: user } = useUser()

  const fetchNotifications = useCallback(
    () =>
      getNotifications({ unreadOnly: true }).then((notifications) =>
        setNotifications(notifications)
      ),
    []
  )

  useEffect(() => {
    if (!user) {
      setNotifications([])
      setClosedIds([])
      setReadIds([])
      return
    }
    fetchNotifications()
    const interval = setInterval(fetchNotifications, ONE_MINUTE_MS)
    return () => clearInterval(interval)
  }, [user, fetchNotifications])

  const handleRead = (id: string) => {
    setReadIds((curIds) => curIds.concat(id))
    markNotificationsAsRead([id])
  }

  const unreadNotifications = useMemo(
    () =>
      notifications.filter(
        (notification) => !readIds.includes(notification.notification_id)
      ),
    [notifications, readIds]
  )

  const value = useMemo(() => ({ unreadNotifications }), [unreadNotifications])

  return (
    <NotificationsContext.Provider value={value}>
      {props.children}
      <Notifications
        notifications={notifications}
        closedIds={closedIds}
        readIds={readIds}
        onClose={(id) => setClosedIds((curIds) => curIds.concat(id))}
        onRead={handleRead}
      />
    </NotificationsContext.Provider>
  )
}

export const useNotifications = () => {
  const ctx = useContext(NotificationsContext)

  if (!ctx) {
    throw new Error(
      'useNotifications must be used within a NotificationsContextProvider'
    )
  }

  return ctx
}
