import type { ChartsTheme } from '../../types/charts'

const PALETTE = {
  darkBlue: '#16164D',
  darkGreen: '#297F0D',
  darkGray: '#808080',
  darkYellow: '#E7B619',
  orange: '#F57350',
  navyBlue: '#38387C',
  green: '#56CC2B',
  gray: '#C0C0C0',
  yellow: '#FFD05A',
  fadedBlue: '#A1A1B7',
  fadedGreen: '#A9CB9E',
  fadedYellow: '#F9EECC',
}

export const chartsTheme: ChartsTheme = {
  palette: Object.values(PALETTE),
}
