import type { Theme } from '../../types'
import { chartsTheme } from './charts'
import { compassTheme } from './compass'

export const THEME_ID = 'whitelabel'

export const buildTheme = async (): Promise<Theme> => ({
  id: THEME_ID,
  charts: chartsTheme,
  compass: compassTheme,
})
