import { css } from '@emotion/css'

import type { CompassTheme } from '../../types/compass'
import b3Logo from './assets/b3-logo.png'
import datawiseBackground from './assets/datawise-background.png'
import DatawiseLogo from './components/DatawiseLogo'
import { palette } from './constants'

export const compassTheme: CompassTheme = {
  presentation: {
    assetLogoData: b3Logo,
    companyLogoData: b3Logo,
    darkBackgroundClass: css`
      background-image: url(${datawiseBackground});
    `,
    darkBackgroundData: datawiseBackground,
    disclaimerText: '',
    summaryPageNumber: css`
      color: ${palette.blue.light};
    `,
    titleDateColor: palette.blue.main,
  },
  scrollBar: {
    backgroundColor: css`
      background-color: #e0e0e0;
    `,
    barColor: css`
      background-color: ${palette.blue.light};
    `,
  },
  sideBar: {
    backgroundColor: css`
      background-color: ${palette.blue.dark};
    `,
    listItemBackgroundColor: css`
      :hover,
      :focus {
        background-color: ${palette.blue.light};
      }
    `,
    searchInputBackgroundColor: css`
      background-color: ${palette.blue.light};
    `,
    Logo: DatawiseLogo,
  },
  topBar: {
    backgroundColor: css`
      background-color: ${palette.blue.dark};
    `,
    searchInputBackgroundColor: css`
      background-color: ${palette.blue.light};
    `,
    searchInputButtonColor: css`
      background-color: ${palette.blue.dark};
      :hover {
        background-color: ${palette.blue.main};
      }
      :focus {
        background-color: ${palette.blue.main};
      }
    `,
    Logo: DatawiseLogo,
  },
}
