import { css } from '@emotion/css'

export default () => (
  <div
    className={css`
      margin-left: 1rem;
      margin-top: 0.25rem;
      height: 1.5rem;
      width: 11.8605rem;
    `}
  />
)
