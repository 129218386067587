import classNames from 'classnames'
import React, { useState } from 'react'

import { useUser } from '../../contexts/UserContext'
import { ArrowLeftIcon, MenuIcon, SearchIcon } from '../icons'
import SearchBar from '../SearchBar'
import UserAvatar from '../UserAvatar'

interface Props {
  className?: string
  onMenuOpen?: () => void
}

const BUTTON_CLASS =
  'bg-white bg-opacity-0 hover:bg-opacity-10 focus:outline-none focus-visible:bg-opacity-10'

const MenuButton: React.FC<{ onOpen?: () => void }> = (props) => (
  <button
    className={BUTTON_CLASS}
    onClick={props.onOpen}
    data-testid="menu-button"
  >
    <MenuIcon className="h-8 text-white" />
  </button>
)

const DesktopTopBar: React.FC<Props> = (props) => {
  const { theme } = useUser()

  if (!theme) {
    return null
  }

  return (
    <span className="hidden sm:contents">
      <div className="flex flex-shrink-0 items-center">
        <MenuButton onOpen={props.onMenuOpen} />
        <theme.compass.topBar.Logo />
      </div>
      <SearchBar className="mx-4 w-full max-w-screen-md" />
      <div className="flex flex-shrink-0 items-center">
        <UserAvatar />
      </div>
    </span>
  )
}

const MobileTopBar: React.FC<Props> = (props) => {
  const [searchExpanded, setSearchExpanded] = useState<boolean>(false)
  const { theme } = useUser()

  if (!theme) {
    return null
  }

  return (
    <span className="contents sm:hidden">
      <div className="flex flex-shrink-0 items-center">
        {searchExpanded ? (
          <button
            className={classNames(
              'flex h-10 w-10 items-center justify-center rounded-full',
              BUTTON_CLASS
            )}
            onClick={() => setSearchExpanded(false)}
          >
            <ArrowLeftIcon className="h-6 w-6 text-white" />
          </button>
        ) : (
          <>
            <MenuButton onOpen={props.onMenuOpen} />
            <theme.compass.topBar.Logo />
          </>
        )}
      </div>
      {searchExpanded ? (
        <SearchBar className="ml-4 w-full" initialFocus />
      ) : (
        <div className="flex flex-shrink-0 items-center">
          <button
            className={classNames(
              'mr-4 flex h-10 w-10 items-center justify-center rounded-full',
              BUTTON_CLASS
            )}
            onClick={() => setSearchExpanded(true)}
          >
            <SearchIcon className="h-6 w-6 text-white" />
          </button>
          <UserAvatar />
        </div>
      )}
    </span>
  )
}

const TopBar: React.FC<Props> = (props) => {
  const { theme } = useUser()

  return (
    <div
      className={classNames(
        'flex h-12 w-full items-center justify-between px-4 py-2',
        theme?.compass.topBar.backgroundColor,
        props.className
      )}
    >
      <DesktopTopBar onMenuOpen={props.onMenuOpen} />
      <MobileTopBar onMenuOpen={props.onMenuOpen} />
    </div>
  )
}

export default TopBar
