import classNames from 'classnames'
import hash from 'object-hash'
import React, { useEffect, useRef, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useHistory, useLocation } from 'react-router-dom'

import { getSearchHits } from '../../clients/compass/search'
import { useUser } from '../../contexts/UserContext'
import { Path } from '../../utils/constants'
import type { SearchableElement } from '../../utils/search'
import { SearchIcon, XIcon } from '../icons'
import SearchHit from '../SearchHit'
import Spinner from '../Spinner'

interface Props {
  className?: string
  initialFocus?: boolean
}

const HITS_PRIORITY: Record<string, number> = {
  report: 1,
  section: 2,
  chart: 3,
  table: 4,
}

const sortHits = (hits: SearchableElement[]) => {
  const priorityIndexedHits = hits.map((hit, idx) => ({
    hit,
    idx,
    priority: HITS_PRIORITY[hit.type_name],
  }))
  const sortedHits = priorityIndexedHits.sort(
    (a, b) => a.priority - b.priority || a.idx - b.idx
  )
  return sortedHits.map(({ hit }) => hit)
}

const SearchBar: React.FC<Props> = (props) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { theme } = useUser()

  const [query, setQuery] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [hits, setHits] = useState<SearchableElement[] | null>(null)
  const [focused, setFocused] = useState<boolean>(false)

  const timeoutRef = useRef<number | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleSubmit = () => {
    if (!query) {
      return
    }

    history.push({
      pathname: Path.SEARCH,
      search: `?q=${encodeURIComponent(query)}`,
    })
  }

  useEffect(() => {
    setHits(null)

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }

    if (query) {
      timeoutRef.current = window.setTimeout(() => {
        setLoading(true)
        getSearchHits(query, { limit: 10 }).then((res) => {
          setLoading(false)
          setHits(sortHits(res))
        })
      }, 200)
    }
  }, [query])

  useEffect(() => {
    setFocused(false)
  }, [pathname])

  useEffect(() => {
    props.initialFocus && inputRef.current?.focus()
  }, [props.initialFocus])

  return (
    <OutsideClickHandler
      display="contents"
      onOutsideClick={() => setFocused(false)}
    >
      <div
        className={classNames(
          'relative flex h-8 rounded-sm p-1 pl-2',
          theme?.compass.topBar.searchInputBackgroundColor ?? 'bg-info-light',
          props.className
        )}
      >
        <input
          ref={inputRef}
          className="h-full w-full bg-transparent text-white focus:outline-none"
          placeholder="Buscar"
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          onKeyDown={(event) => event.key === 'Enter' && handleSubmit()}
          onFocus={() => setFocused(true)}
        />
        <button
          className={classNames(
            'flex h-6 w-6 items-center justify-center rounded-full bg-white bg-opacity-0 hover:bg-opacity-10 focus:outline-none focus-visible:bg-opacity-10',
            { invisible: !query }
          )}
          onClick={() => setQuery('')}
        >
          <XIcon className="h-4 w-4 text-white text-opacity-50" />
        </button>
        <button
          className={classNames(
            'ml-1 flex h-full w-12 items-center justify-center text-white  focus:outline-none',
            theme?.compass.topBar.searchInputButtonColor ??
              'bg-info-dark hover:bg-info-main focus:bg-info-main'
          )}
          onClick={handleSubmit}
        >
          <SearchIcon className="h-5 w-5" />
        </button>
        {focused && (loading || hits) && (
          <div className="absolute bottom-0 left-0 z-20 w-full translate-y-full transform shadow-md">
            <div className="mt-2">
              {loading ? (
                <div className="flex items-center justify-center bg-white p-4">
                  <Spinner className="text-success-dark" size={24} />
                </div>
              ) : hits!.length === 0 ? (
                <div className="flex items-center justify-center bg-white p-4 text-gray-400">
                  Nenhum resultado encontrado
                </div>
              ) : (
                <div className="max-h-96 overflow-y-auto bg-white">
                  {hits!.map((hit) => (
                    <SearchHit
                      key={hash(hit)}
                      className="border-b p-2 hover:bg-gray-100"
                      hit={hit}
                      showHitType
                    />
                  ))}
                  <button
                    className="w-full p-2 text-gray-600 hover:bg-gray-100 focus:outline-none"
                    onClick={handleSubmit}
                  >
                    Ver todos os resultados
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
}

export default SearchBar
