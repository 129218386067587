import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  className?: string
  to: string
}

export const NodeLink: React.FC<Props> = (props) => {
  const isExternalLink = useMemo(() => !props.to.startsWith('/'), [props.to])

  return isExternalLink ? (
    <a
      className={props.className}
      href={props.to}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  ) : (
    <Link className={props.className} to={props.to}>
      {props.children}
    </Link>
  )
}
