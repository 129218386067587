import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'

import CheckCircleIcon from '../icons/CheckCircle'
import ExclamationCircleIcon from '../icons/ExclamationCircle'
import InfoIcon from '../icons/Info'
import XIcon from '../icons/X'

const DEFAULT_OPEN_DURATION_MS = 10000

interface Props {
  open: boolean
  onClose: () => void
  onExit?: () => void
  message: string
  type?: 'success' | 'fail' | 'info'
  autoDismissTime?: number // 0 disables autodismiss
}

const Toast: React.FC<Props> = (props) => {
  const timeoutRef = useRef<any>(null)
  const lastOpen = useRef<boolean>(false)

  useEffect(() => {
    if (props.autoDismissTime === 0) {
      return
    }
    if (props.open && timeoutRef.current === null) {
      timeoutRef.current = setTimeout(
        props.onClose,
        props.autoDismissTime ?? DEFAULT_OPEN_DURATION_MS
      )
    } else if (!props.open && timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }, [props.onClose, props.open, props.autoDismissTime])

  useEffect(() => {
    if (!props.open && lastOpen.current) {
      setTimeout(() => props.onExit?.(), 300)
    }
    lastOpen.current = props.open
  }, [props])

  const handleMouseOver = () => {
    if (props.autoDismissTime === 0) {
      return
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }

  const handleMouseLeave = () => {
    if (props.autoDismissTime === 0) {
      return
    }
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(
        props.onClose,
        props.autoDismissTime ?? DEFAULT_OPEN_DURATION_MS
      )
    }
  }

  return (
    <div
      className={classNames(
        'fixed bottom-4 left-4 z-50 flex max-w-xl transform flex-nowrap items-center justify-between rounded-md bg-white p-5 shadow-2xl transition-transform duration-300',
        { 'translate-y-48': !props.open }
      )}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex flex-nowrap items-center">
        {props.type === 'success' && (
          <CheckCircleIcon className="mr-4 h-6 w-6 flex-shrink-0 text-success-dark" />
        )}
        {props.type === 'fail' && (
          <ExclamationCircleIcon className="flex-shrinkk-0 mr-4 h-6 w-6 text-red-500" />
        )}
        {props.type === 'info' && (
          <InfoIcon className="mr-4 h-6 w-6 flex-shrink-0 text-blue-500" />
        )}
        <span>{props.message}</span>
      </div>
      <button
        className="ml-8 flex-shrink-0 text-gray-500 hover:text-gray-800 focus:text-gray-800 focus:outline-none"
        onClick={props.onClose}
      >
        <XIcon className="h-6 w-6" />
      </button>
    </div>
  )
}

export default Toast
