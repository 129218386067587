import { css } from '@emotion/css'

import type { CompassTheme } from '../../types/compass'
import EmptyLogo from './components/EmptyLogo'

const SINGLE_1F2937_PIXEL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOU1zT/DwACbgGAG5/xZgAAAABJRU5ErkJggg=='

const SINGLE_TRANSPARENT_PIXEL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

export const compassTheme: CompassTheme = {
  presentation: {
    assetLogoData: SINGLE_TRANSPARENT_PIXEL,
    companyLogoData: SINGLE_TRANSPARENT_PIXEL,
    darkBackgroundClass: css`
      background-color: #1f2937;
    `,
    darkBackgroundData: SINGLE_1F2937_PIXEL,
    disclaimerText: '',
    summaryPageNumber: css`
      color: #297f0d;
    `,
    titleDateColor: '#30ba09',
  },
  scrollBar: {
    backgroundColor: css`
      background-color: #e0e0e0;
    `,
    barColor: css`
      background-color: #30ba09;
    `,
  },
  sideBar: {
    backgroundColor: css`
      background-color: #1f2937;
    `,
    listItemBackgroundColor: css`
      :hover,
      :focus {
        background-color: #297f0d;
      }
    `,
    searchInputBackgroundColor: css`
      background-color: #353e4b;
    `,
    Logo: EmptyLogo,
  },
  topBar: {
    backgroundColor: css`
      background-color: #1f2937;
    `,
    searchInputBackgroundColor: css`
      background-color: #353e4b;
    `,
    searchInputButtonColor: css`
      background-color: #1f2937;
      :hover,
      :focus {
        background-color: #2b2b2b;
      }
    `,
    Logo: EmptyLogo,
  },
}
