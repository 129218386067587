import classNames from 'classnames'
import React, { useState } from 'react'
import { positionValues, Scrollbars } from 'react-custom-scrollbars-2'

import { useUser } from '../../contexts/UserContext'

interface Props {
  className?: string
}

const Scrollbar: React.FC<Props> = (props) => {
  const [showScrollbar, setShowScrollbar] = useState<boolean>(false)
  const { theme } = useUser()

  const handleUpdate = (values: positionValues) => {
    const { clientHeight, scrollHeight } = values
    setShowScrollbar(scrollHeight > clientHeight)
  }

  return (
    <Scrollbars
      className={props.className}
      renderThumbVertical={(props) => (
        <div
          {...props}
          className={classNames(
            'rounded-full',
            theme?.compass.scrollBar.barColor ?? 'bg-success-main'
          )}
        />
      )}
      renderTrackVertical={
        showScrollbar
          ? (props) => (
              <div
                {...props}
                className={classNames(
                  'top-0 bottom-0 right-0 w-1 rounded-full',
                  theme?.compass.scrollBar.backgroundColor ?? 'bg-[#e0e0e0]'
                )}
              />
            )
          : undefined
      }
      onUpdate={handleUpdate}
    >
      <div className={classNames({ 'pr-4': showScrollbar })}>
        {props.children}
      </div>
    </Scrollbars>
  )
}

export default Scrollbar
