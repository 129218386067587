import classNames from 'classnames'
import React from 'react'

import Spinner from '../Spinner'

interface Props {
  loading: boolean
  containerClassName?: string
  spinnerClassName?: string
  spinnerSize?: number
}

const LoadingContainer: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {props.loading ? (
        <div
          className={classNames(
            'flex h-full items-center justify-center',
            props.containerClassName
          )}
        >
          <Spinner
            className={classNames('text-success-dark', props.spinnerClassName)}
            size={props.spinnerSize}
          />
        </div>
      ) : (
        props.children
      )}
    </React.Fragment>
  )
}

export default LoadingContainer
