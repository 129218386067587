import React from 'react'
import { Link } from 'react-router-dom'

import type { SearchableElement } from '../../utils/search'

interface Props {
  hit: SearchableElement
  className?: string
  showHitType?: boolean
}

const getDisplayTypeName = (typeName: SearchableElement['type_name']) =>
  typeName === 'report'
    ? 'Relatório'
    : typeName === 'section'
    ? 'Seção'
    : typeName === 'chart'
    ? 'Gráfico'
    : 'Tabela'

const SearchHit: React.FC<Props> = (props) => (
  <Link to={props.hit.url}>
    <div className={props.className}>
      {props.showHitType && (
        <div className="text-sm font-bold">
          {getDisplayTypeName(props.hit.type_name)}
        </div>
      )}
      <div>
        {props.hit.type_name === 'report'
          ? props.hit.report
          : props.hit.type_name === 'section'
          ? props.hit.section
          : props.hit.title}
      </div>
      {props.hit.type_name !== 'report' && (
        <div className="text-sm">Relatório: {props.hit.report}</div>
      )}
      {props.hit.type_name !== 'report' &&
        props.hit.type_name !== 'section' && (
          <div className="text-sm">Seção: {props.hit.section}</div>
        )}
    </div>
  </Link>
)

export default SearchHit
