import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import type { ThemeID } from './themes'
import { getTheme } from './themes'
import type { Theme } from './types'

interface IThemeContext {
  theme: Theme | null
  changeTheme: (themeId: ThemeID) => Promise<void>
  loading: boolean
}

const ThemeContext = createContext<IThemeContext>({
  theme: null,
  changeTheme: () => Promise.resolve(),
  loading: false,
})

export const ThemeContextProvider: React.FC = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [theme, setTheme] = useState<Theme | null>(null)

  const changeTheme = useCallback(async (themeId: ThemeID) => {
    setLoading(true)

    return getTheme(themeId)
      .then(setTheme)
      .catch((err) => {
        throw new Error(`Failed to get theme "${themeId}": ${err}`)
      })
      .finally(() => setLoading(false))
  }, [])

  const value = useMemo(
    () => ({ theme, changeTheme, loading }),
    [theme, changeTheme, loading]
  )

  return (
    <ThemeContext.Provider value={value}>
      {props.children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeContext)

  if (!context) {
    throw new Error('useTheme must be used within a ThemeContextProvider')
  }

  return context
}
